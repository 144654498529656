import { render, staticRenderFns } from "./AcessorioModulo.vue?vue&type=template&id=790f4955&scoped=true&"
import script from "./AcessorioModulo.vue?vue&type=script&lang=js&"
export * from "./AcessorioModulo.vue?vue&type=script&lang=js&"
import style0 from "./AcessorioModulo.vue?vue&type=style&index=0&id=790f4955&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "790f4955",
  null
  
)

export default component.exports