<template>
  <div>
    <vx-card>
       <vs-row vs-type="flex" vs-justify="space-between" >
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
          <label class="vs-input--label mr-2">Marca</label>
          <v-select                                               
            :options="CONSTANTE_MARCAS_ACESSORIO_MODULO" 
            v-model="filtro.marca"                      
            class="w-full vs-input--label">
          </v-select>  
          <label class="vs-input--label mr-2">Serial</label>
          <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.serial" @keyup.enter="fetchLista(filtro)" />                   
          <label class="vs-input--label mr-2">Status</label>
          <v-select                                               
            :options="CONSTANTE_STATE_ACESSORIO" 
            v-model="filtro.stateAcessorio"                      
            class="w-full vs-input--label">
          </v-select>
          <vs-checkbox v-model="filtro.emVeiculos" class="mr-auto"><small>Em Veículos</small></vs-checkbox>   
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-justify="space-between" class="mt-2">        
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
         <v-select                                               
            :options="CONSTANTE_TIPO_CLIENTE_PROPRIETARIO" 
            v-model="filtro.tipoClienteProprietario"  
            :clearable="false"       
            style="min-width: 160px"             
            class="vs-input--label">
          </v-select>
           <v-select  
                    label="nome"                                             
                    :reduce="r => r.id"
                    :options="listaClientes" 
                    v-model="filtro.idClienteProprietario"                      
                    class="w-full vs-input--label">
                </v-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">          
          <label class="vs-input--label mr-2">Tipo</label>
          <v-select                                               
              :options="CONSTANTE_TIPO_ACESSORIO_MODULO" 
              v-model="filtro.tipo"                  
              :reduce="r => r.data"   
              class="w-full vs-input--label mr-2">
          </v-select>          
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <vs-button class="mr-2" color="primary" type="filled" icon-pack="feather" icon="icon-search" @click="fetchLista(filtro)"></vs-button>
          <vs-button class="mr-2" color="dark" type="border" icon-pack="feather" icon="icon-file" @click="limparFiltros"></vs-button>  
          <vs-button class="mr-2" color="warning" type="filled" icon-pack="feather" icon="icon-plus" @click="routeNovoRegistro"></vs-button>          
          <vs-button color="rgb(11, 189, 135)" type="filled" icon-pack="feather" icon="icon-save" @click="exportarParaCSV"></vs-button>          
        </vs-col>
      </vs-row>
      
    </vx-card>

    <p class="text-danger mt-2 text-sm"> {{dadosDaGrid.length }} registros </p>
     
    <ag-grid-vue
      ref="agGridTable"           
      :gridOptions="configuracaoGrid.gridOptions"
      class="ag-theme-material ag-grid-table ag-grid-altura mt-1"
      :columnDefs="configuracaoGrid.columnDefs"
      :defaultColDef="configuracaoGrid.defaultColDef"
      :rowData="dadosDaGrid"
      :rowHeight="20"
      :headerHeight="25"        
      rowSelection="single"
      colResizeDefault="shift"
      @row-selected="onRowSelectedRegistro"    
      :animateRows="true"
      :floatingFilter="false"
      :pagination="false"
      :paginationAutoPageSize="false"
      :suppressPaginationPanel="true"
      >
    </ag-grid-vue>      
    
  </div>

</template>

<script>
import axios from "@/axios.js"
import { AgGridVue } from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { CONSTANTE_STATE_ACESSORIO } from '@/constantesComboBox.js'
import { CONSTANTE_MARCAS_ACESSORIO_MODULO } from '@/constantesComboBox.js'
import { CONSTANTE_TIPO_CLIENTE_PROPRIETARIO } from '@/constantesComboBox.js'
import { CONSTANTE_TIPO_ACESSORIO_MODULO } from '@/constantesComboBox.js'

const URL_SERVLET = "/AcessorioModuloRest";
const NAME_ROUTE_EDIT = "cadastro-acessorios-modulos-editar";

export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      filtro : {codigo: null, serial:  null, placaVeiculo: null, nomeTecnico: null, tipoModulo: null, 
      marca: null, tipoClienteProprietario: 'CLIENTE', stateAcessorio: null,  emVeiculos: false, idClienteProprietario: null},
      configuracaoGrid: { 
          gridApi: null, gridOptions: {}, 
          defaultColDef: { sortable: true, resizable: true, suppressMenu: true  },
          columnDefs: [        
            { headerName: 'Marca', field: 'marca', width: 130 },           
            { headerName: 'Tipo', field: 'tipo', width: 130 },  
            { headerName: 'Modelo', field: 'modelo', width: 130 },  
            { headerName: 'Serial', field: 'serial', width: 120 },                                
            { headerName: 'Status', field: 'stateAcessorio', width: 100},                                
            { headerName: 'Informações', field: 'ultimaComunicacao', width: 100,
              valueGetter: function(params) {
                  if (params.data.stateAcessorio === 'ATIVO') {
                    if (params.data.hasOwnProperty('placaVeiculo')) {
                      return params.data.placaVeiculo + '-' + params.data.nomeClienteVeiculo;
                    } 
                  } else if (params.data.stateAcessorio === 'COM TECNICO') {
                    if (params.data.hasOwnProperty('nomeTecnico')) {
                      return params.data.nomeTecnico;
                    }  
                  } else if (params.data.stateAcessorio === 'DE TERCEIRO') {
                    if (params.data.hasOwnProperty('nomeClienteProprietario')) {
                      return params.data.nomeClienteProprietario;
                    }  
                  }  else if (params.data.stateAcessorio === 'COM CLIENTE') {
                    if (params.data.hasOwnProperty('nomeClienteComPosse')) {
                      return params.data.nomeClienteComPosse;
                    }  
                  }
                  
                  return ' ';
              }},           
          ]
      },
      dadosDaGrid: [],
      totalRegistros: 0,
      listaClientes : [],     
    }
  },  
  created() {
      this.CONSTANTE_STATE_ACESSORIO = CONSTANTE_STATE_ACESSORIO;
      this.CONSTANTE_MARCAS_ACESSORIO_MODULO = CONSTANTE_MARCAS_ACESSORIO_MODULO;
      this.CONSTANTE_TIPO_CLIENTE_PROPRIETARIO = CONSTANTE_TIPO_CLIENTE_PROPRIETARIO;
      this.CONSTANTE_TIPO_ACESSORIO_MODULO = CONSTANTE_TIPO_ACESSORIO_MODULO;
  },
  methods: {
    limparFiltros() {
      this.filtro = { serial:  null, tipo: null, marca: null, tipoClienteProprietario: 'CLIENTE', stateAcessorio: null, emVeiculos: false, idClienteProprietario: null};      
    },
    fetchLista(pFiltro) {     
      this.$vs.loading() 
      const params = new URLSearchParams();
      params.append('method', 'FindAll');
      params.append('orderDirection', 'ASC');
      params.append('outputFormat', 'JSON');

      if (pFiltro.marca) {
        params.append('filter2', pFiltro.marca.data);
      }

      if (pFiltro.serial) {
        params.append('filter1', pFiltro.serial);
      }

      if (pFiltro.stateAcessorio) {
        params.append('pStateAcessorioModulo', pFiltro.stateAcessorio.data);
      }

      if (pFiltro.tipo) {
        params.append('tipo', pFiltro.tipo);
      }

      params.append('emVeiculos', pFiltro.emVeiculos);

      if (this.filtro.idClienteProprietario) {
        params.append('tipoClienteProprietario', this.filtro.tipoClienteProprietario);
        params.append('idClienteProprietario', this.filtro.idClienteProprietario);
      }

      axios.post(URL_SERVLET, params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.dadosDaGrid = response.data;         
              this.totalRegistros = this.dadosDaGrid.length;                              
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaPoI - " + error); 
      })    
                        
    },
    routeNovoRegistro() {
      this.$store.dispatch('setFiltroCadastroAcessoriosModulos', this.filtro);

      let self_ = this;      
      self_.$router.push({
            name: NAME_ROUTE_EDIT                     
      })
    },
    onRowSelectedRegistro(evento) {
      this.$store.dispatch('setFiltroCadastroAcessoriosModulos', this.filtro);

      let self_ = this;
      let registro = evento.node.data;
      /* eslint-disable no-console */ console.log('registro=' + registro.hasOwnProperty('codigo'));      
      
      self_.$router.push({
            name: NAME_ROUTE_EDIT,
            params: {
              registroOriginal: registro
            }
      })

    },
    exportarParaCSV() {
      var params = {columnSeparator: ';', fileName: 'AcessoriosModulos.csv'};
      this.configuracaoGrid.gridApi.exportDataAsCsv(params);
    },
    fetchListaClientes() {     
      this.$vs.loading() 
      const params = new URLSearchParams();
      params.append('method', 'FindAll');
      params.append('orderDirection', 'ASC');
      params.append('outputFormat', 'JSON');
      params.append('naoExibirInativos', 'true');
      
      axios.post("/ClientesRest", params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.listaClientes = response.data;         
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
      })    
                        
    }, 
    
  },
  mounted() {
    this.configuracaoGrid.gridApi = this.configuracaoGrid.gridOptions.api;
    this.configuracaoGrid.gridApi.sizeColumnsToFit();    
    this.fetchListaClientes();
    if (this.$store.getters.filtroCadastroAcessoriosModulos) {
      this.filtro = JSON.parse(JSON.stringify(this.$store.getters.filtroCadastroAcessoriosModulos));
    }
    this.fetchLista(this.filtro);
    
  }
}

</script>
<style lang="scss" scoped>
  .ag-grid-altura{
    height: calc(100vh - 265px);
  }
</style>